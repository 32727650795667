// src/pages/AddBlogs.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlogForm from '../../components/Blog/BlogForm';
import '../../styles/Blogs/AddBlogs.css';
import { getAuth } from 'firebase/auth';

const defaultAuthorImage = '/default-profile.png'; // Path to default image in public folder

const AddBlogs = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;


  
  const handleBlogAdded = (blogId) => {
    navigate(`/blogs/${blogId}`); // Redirect to the newly added blog's details page
  };

  if (!user) {
    return <div>Please log in to add a blog.</div>; // Handle case where user is not authenticated
  }

  // Use default image if user.photoURL is not available
  const authorImage = user.photoURL || defaultAuthorImage;

  return (
    <div>
      <h1 className="page-title">Hi {user.displayName || 'User'}, Write Your Blog</h1>
      <p></p>
      <BlogForm
        onBlogAdded={handleBlogAdded}
        author={user.displayName}
        authorEmail={user.email}  // Include email if needed
        authorImage={authorImage} // Use the default image if needed
      />
    </div>
  );
};

export default AddBlogs;
