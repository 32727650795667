import pdfIcon from './pdf.png';
import docx from './docx.png'
import zip from './zip.png'
// Import other icons similarly

const icons = {
  pdf: pdfIcon,
  docx: docx,
  zip:zip
  // Add other file types here
};

export default icons;
