import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import { useParams } from "react-router-dom"; // Import useParams to get URL parameters
import "../../styles/Enquiry/EnquiryDetails.css"; // Assuming you have a CSS file for styling
import config from "../../config"; // Import the config file

const EnquiryDetails = () => {
  const { id } = useParams(); // Extract the id from the URL
  const [enquiry, setEnquiry] = useState(null); // State to hold enquiry details
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    // Fetch enquiry details when the component mounts
    const fetchEnquiry = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/enquiry/${id}`);
        setEnquiry(response.data); // Set the enquiry data
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        setError("Failed to fetch enquiry details.");
        setLoading(false);
      }
    };

    fetchEnquiry();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="enquiry-details-container">
      <h1>Enquiry Details</h1>
      <div className="enquiry-details">
        <p>
          <strong>Name:</strong> {enquiry.name}
        </p>
        <p>
          <strong>Email:</strong> {enquiry.email}
        </p>
        <p>
          <strong>Category:</strong> {enquiry.category}
        </p>
        <p>
          <strong>Subject:</strong> {enquiry.subject}
        </p>
        <p>
          <strong>Message:</strong> {enquiry.message}
        </p>
        <p>
          <strong>Created At:</strong>{" "}
          {new Date(enquiry.createdAt).toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default EnquiryDetails;
