import React, { useState } from "react";
import axios from "axios";
import config from "../../config";
import "./sendemail.css";

const SendGenericEmail = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [content, setContent] = useState(""); // State for email content
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Split the email input by commas and trim whitespace
    const emailArray = email.split(",").map((addr) => addr.trim());

    const emailData = {
      to: emailArray, // Pass the array of email addresses
      subject: subject,
      name: name,
      content: content, // Include content in request
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}/send-generic-email`,
        emailData
      );
      setMessage("Email sent successfully");
      setSubmitted(true);
    } catch (error) {
      setMessage("Error sending email");
      console.error("Error:", error);
    }
  };

  return (
    <div className="background-container">
      <h1 className="title">Send Generic Email</h1>
      {!submitted ? (
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label className="label">Email(s):</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input"
              placeholder="Enter multiple email addresses separated by commas"
            />
          </div>
          <div className="form-group">
            <label className="label">Subject:</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Content:</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              className="textarea"
              placeholder="Enter the content of the email"
            />
          </div>
          <button type="submit" className="button">
            Send Email
          </button>
        </form>
      ) : (
        <div className="message-card">
          <p className="message">{message}</p>
        </div>
      )}
    </div>
  );
};

export default SendGenericEmail;
