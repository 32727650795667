import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../styles/JobApplication/JobApplications.css'; // Add necessary styles
import config from '../../config'; // Import the config file

const JobApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/job-applications`);
        setApplications(response.data);
      } catch (err) {
        console.error('Error fetching job applications:', err);
        setError('Failed to fetch job applications.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  if (loading) return <p className="loading">Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="job-applications-container">
      <h1 className="title">Job Applications</h1>
      {applications.length > 0 ? (
        <div className="applications-list">
          {applications.map((application) => (
            <div key={application._id} className="job-application-card">
              <h2 className="applicant-name">{application.fullName}</h2>
              <p><strong>Email:</strong> {application.email}</p>
              <p><strong>Phone Number:</strong> {application.phoneNumber}</p>
              <p><strong>Applied On:</strong> {new Date(application.createdAt).toLocaleDateString()}</p>
              <Link to={`/job-applications/${application._id}`} className="view-details-link">View Details</Link>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-applications">No job applications found.</p>
      )}
    </div>
  );
};

export default JobApplications;
