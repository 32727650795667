// App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/common/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/blog/Blogs";
import BlogDetail from "./pages/blog/BlogDetail";
import AddBlogs from "./pages/blog/AddBlogs";
import EditBlog from "./pages/blog/EditBlog";
import Courses from "./pages/course/Courses";
import CourseDetail from "./pages/course/CourseDetail";
import AddCourse from "./pages/course/AddCourse";
import EditCourse from "./pages/course/EditCourse";
import Documents from "./pages/documents/Documents";
import DocumentDetail from "./pages/documents/DocumentDetails";
import AddDocument from "./pages/documents/AddDocument";
import Login from "./pages/Users/Login";
import PrivateRoute from "./components/common/PrivateRoute";
import RegisterUser from "./pages/Users/RegisterUser";
import Logout from "./pages/Users/Logout";
import Admin from "./pages/Admin/Admin";
import WorkWithUs from "./pages/JobApplication/WorkWithUs"; // Import the WorkWithUs component
import JobApplications from "./pages/JobApplication/JobApplications";
import JobApplicationDetails from "./pages/JobApplication/JobApplicationDetails";
import ContactUs from "./pages/Enquiry/ContactUs";
import Enquiry from "./pages/Enquiry/Enquiry";
import EnquiryDetails from "./pages/Enquiry/EnquiryDetails";
import AuditLog from "./pages/Admin/AuditLogs";
import LoginActivity from "./pages/Admin/LoginActivity";
import SendGenericEmail from "./pages/Admin/sendemail";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route
            path="/addblogs"
            element={<PrivateRoute element={AddBlogs} />}
          />
          <Route path="/editblogs/:id" element={<EditBlog />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:id" element={<CourseDetail />} />
          <Route path="/addcourse" element={<AddCourse />} />
          <Route
            path="/courses/edit/:id"
            element={<PrivateRoute element={EditCourse} isAdminRoute={true} />}
          />
          <Route path="/documents" element={<Documents />} />
          <Route path="/documents/:id" element={<DocumentDetail />} />
          <Route
            path="/adddocument"
            element={<PrivateRoute element={AddDocument} isAdminRoute={true} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterUser />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/admin"
            element={<PrivateRoute element={Admin} isAdminRoute={true} />}
          />
          <Route
            path="/sendemail"
            element={
              <PrivateRoute element={SendGenericEmail} isAdminRoute={true} />
            }
          />
          <Route
            path="/work-with-us"
            element={<PrivateRoute element={WorkWithUs} />}
          />
          <Route
            path="/job-applications"
            element={
              <PrivateRoute element={JobApplications} isAdminRoute={true} />
            }
          />
          <Route
            path="/job-applications/:id"
            element={
              <PrivateRoute
                element={JobApplicationDetails}
                isAdminRoute={true}
              />
            }
          />
          <Route
            path="/enquiry"
            element={<PrivateRoute element={Enquiry} isAdminRoute={true} />}
          />
          <Route
            path="/enquiry/:id"
            element={
              <PrivateRoute element={EnquiryDetails} isAdminRoute={true} />
            }
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/auditlogs/"
            element={<PrivateRoute element={AuditLog} isAdminRoute={true} />}
          />
          <Route
            path="/loginactivity/"
            element={
              <PrivateRoute element={LoginActivity} isAdminRoute={true} />
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
