import React from 'react';
import '../../styles/Admin/AdminAccessDenied.css'; // Import the CSS file

const AdminAccessDenied = () => {
  return (
    <div className="access-denied-container">
      <h2 className="access-denied-title">Admin Access Needed</h2>
      <p className="access-denied-message">
        You do not have the required permissions to view this page.
      </p>
    </div>
  );
};

export default AdminAccessDenied;

