import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import "../../styles/Admin/Admin.css";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [courses, setCourses] = useState([]);
  const [jobApplications, setJobApplications] = useState([]); // New state for job applications
  const [syncing, setSyncing] = useState(false);
  const [roles, setRoles] = useState({});
  const [view, setView] = useState("users");
  const [currentId, setCurrentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [enquiries, setEnquiries] = useState([]); // New state for enquiries

  useEffect(() => {
    const fetchData = async (endpoint, setter) => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.apiUrl}${endpoint}`);
        setter(response.data);
      } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
      } finally {
        setLoading(false);
      }
    };

    if (view === "users") {
      fetchData("/admin/users", setUsers);
    } else if (view === "blogs") {
      fetchData("/blogs", setBlogs);
    } else if (view === "documents") {
      fetchData("/documents", setDocuments);
    } else if (view === "courses") {
      fetchData("/courses", setCourses);
    } else if (view === "job-applications") {
      fetchData("/job-applications", setJobApplications);
    } else if (view === "enquiries") {
      // Fetch enquiries
      fetchData("/enquiry", setEnquiries);
    }
  }, [view]);

  const handleSyncUsers = async () => {
    setSyncing(true);
    try {
      await axios.get(`${config.apiUrl}/admin/sync-users`);
      const response = await axios.get(`${config.apiUrl}/admin/users`);
      setUsers(response.data);
      alert("Users synced successfully!");
    } catch (error) {
      console.error("Error syncing users:", error);
      alert("Error syncing users.");
    } finally {
      setSyncing(false);
    }
  };

  const handleRoleChange = (uid, role) => {
    setRoles((prevRoles) => ({
      ...prevRoles,
      [uid]: role,
    }));
  };

  const handleSaveRoles = async () => {
    try {
      for (const uid in roles) {
        await axios.put(`${config.apiUrl}/admin/update-role`, {
          uid,
          role: roles[uid],
        });
      }
      alert("Roles updated successfully!");
    } catch (error) {
      console.error("Error updating roles:", error);
      alert("Error updating roles.");
    }
  };

  const handleDeleteUser = async (uid) => {
    try {
      await axios.delete(`${config.apiUrl}/admin/delete-user/${uid}`);
      setUsers(users.filter((user) => user.uid !== uid));
      alert("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Error deleting user.");
    }
  };

  const handleDeleteBlog = async (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      try {
        await axios.delete(`${config.apiUrl}/blogs/${id}`);
        setBlogs(blogs.filter((blog) => blog._id !== id));
        alert("Blog deleted successfully!");
      } catch (error) {
        console.error("Error deleting blog:", error);
        alert("Error deleting blog.");
      }
    }
  };

  const handleDeleteEnquiry = async (id) => {
    if (window.confirm("Are you sure you want to delete this enquiry?")) {
      try {
        await axios.delete(`${config.apiUrl}/enquiry/${id}`);
        setEnquiries(enquiries.filter((enquiry) => enquiry._id !== id)); // Update state correctly
        alert("Enquiry deleted successfully!");
      } catch (error) {
        console.error("Error deleting enquiry:", error);
        alert("Error deleting enquiry.");
      }
    }
  };

  const handleDeleteDocument = async (id) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        await axios.delete(`${config.apiUrl}/documents/${id}`);
        setDocuments(documents.filter((document) => document._id !== id));
        alert("Document deleted successfully!");
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Error deleting document.");
      }
    }
  };

  const handleDeleteCourse = async (id) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      try {
        await axios.delete(`${config.apiUrl}/courses/${id}`);
        setCourses(courses.filter((course) => course._id !== id));
        alert("Course deleted successfully!");
      } catch (error) {
        console.error("Error deleting course:", error);
        alert("Error deleting course.");
      }
    }
  };

  const handleDeleteJobApplication = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this job application?")
    ) {
      try {
        await axios.delete(`${config.apiUrl}/job-applications/${id}`);
        setJobApplications(
          jobApplications.filter((application) => application._id !== id)
        );
        alert("Job application deleted successfully!");
      } catch (error) {
        console.error("Error deleting job application:", error);
        alert("Error deleting job application.");
      }
    }
  };

  const handleViewDetails = (id, type) => {
    setCurrentId(id);
    let path = "";
    switch (type) {
      case "blogs":
        path = `/blogs/${id}`;
        break;
      case "documents":
        path = `/documents/${id}`;
        break;
      case "courses":
        path = `/courses/${id}`;
        break;
      case "job-applications":
        path = `/job-applications/${id}`;
        break;
      case "enquiry":
        path = `/enquiry/${id}`;
        break;
      default:
        path = "/";
    }
    navigate(path);
  };

  return (
    <div className="admin-container">
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <nav>
          <ul>
            <li>
              <button onClick={() => setView("users")}>Users</button>
            </li>
            <li>
              <button onClick={() => setView("blogs")}>Blogs</button>
            </li>
            <li>
              <button onClick={() => setView("documents")}>Documents</button>
            </li>
            <li>
              <button onClick={() => setView("courses")}>Courses</button>
            </li>
            <li>
              <button onClick={() => setView("job-applications")}>
                Job Applications
              </button>
            </li>
            <li>
              <button onClick={() => setView("enquiries")}>Enquiries</button>
            </li>{" "}
            {/* New navigation item */}
          </ul>
        </nav>
      </div>
      <div className="main-content">
        {loading && <div>Loading...</div>}

        {currentId && <div>Currently Viewing ID: {currentId}</div>}

        {view === "users" && !loading && (
          <div className="users-container">
            <h1>Users</h1>
            <div className="table-wrapper">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>UID</th>
                      <th>Email</th>
                      <th>Display Name</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.uid}>
                        <td>{user.uid}</td>
                        <td>{user.email}</td>
                        <td>{user.displayName}</td>
                        <td>
                          <select
                            value={roles[user.uid] || user.role} // Use existing role if not in roles state
                            onChange={(e) =>
                              handleRoleChange(user.uid, e.target.value)
                            }
                          >
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                          </select>
                        </td>
                        <td>
                          <button onClick={() => handleDeleteUser(user.uid)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <p></p>
            <div className="user-actions-container">
              <button
                onClick={handleSyncUsers}
                disabled={syncing}
                className="btn-sync"
              >
                {syncing ? "Syncing..." : "Sync"}
              </button>
              <button onClick={handleSaveRoles} className="btn-save">
                Save
              </button>
            </div>
          </div>
        )}

        {view === "blogs" && !loading && (
          <div className="admin-blogs-container">
            <h1>Blogs</h1>
            <button className="btn-add" onClick={() => navigate("/addblogs")}>
              Add Blog
            </button>
            <div className="table-wrapper">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Author</th>
                      <th>Published Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blogs.map((blog) => (
                      <tr key={blog._id}>
                        <td>{blog.title}</td>
                        <td>{blog.author}</td>
                        <td>{new Date(blog.createdAt).toLocaleDateString()}</td>
                        <td>
                          <button
                            onClick={() => handleViewDetails(blog._id, "blogs")}
                          >
                            View
                          </button>
                          <button onClick={() => handleDeleteBlog(blog._id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {view === "documents" && !loading && (
          <div className="documents-container">
            <h1>Documents</h1>
            <button
              className="btn-add"
              onClick={() => navigate("/adddocument")}
            >
              Add Document
            </button>
            <div className="table-wrapper">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>File</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((doc) => (
                      <tr key={doc._id}>
                        <td>{doc.title}</td>
                        <td>{doc.description}</td>
                        <td>
                          <a
                            href={doc.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View File
                          </a>
                        </td>{" "}
                        {/* Display as link */}
                        <td>
                          <button
                            onClick={() =>
                              handleViewDetails(doc._id, "documents")
                            }
                          >
                            View
                          </button>
                          <button onClick={() => handleDeleteDocument(doc._id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {view === "courses" && !loading && (
          <div className="courses-container">
            <h1>Courses</h1>
            <button className="btn-add" onClick={() => navigate("/addcourse")}>
              Add Course
            </button>
            <div className="table-wrapper">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courses.map((course) => (
                      <tr key={course._id}>
                        <td>{course.title}</td>
                        <td>{course.description}</td>
                        <td>
                          <button
                            onClick={() =>
                              handleViewDetails(course._id, "courses")
                            }
                          >
                            View
                          </button>
                          <button
                            onClick={() => handleDeleteCourse(course._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {view === "enquiries" && !loading && (
          <div className="enquiries-container">
            <h1>Enquiries</h1>
            <div className="table-wrapper">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Enquirer Name</th>
                      <th>Email</th>
                      <th>Message</th>
                      <th>Submitted On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries.map((enquiry) => (
                      <tr key={enquiry._id}>
                        <td>{enquiry.name}</td>
                        <td>{enquiry.email}</td>
                        <td>{enquiry.message}</td>
                        <td>
                          {new Date(enquiry.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleViewDetails(enquiry._id, "enquiry")
                            }
                          >
                            View
                          </button>
                          <button
                            onClick={() => handleDeleteEnquiry(enquiry._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {view === "job-applications" && !loading && (
          <div className="job-applications-container">
            <h1>Job Applications</h1>
            <div className="table-wrapper">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Applicant Name</th>
                      <th>Email</th>
                      <th>Resume</th>
                      <th>Applied On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobApplications.map((application) => (
                      <tr key={application._id}>
                        <td>{application.fullName}</td>
                        <td>{application.email}</td>
                        <td>
                          <a
                            href={application.resume}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Resume
                          </a>{" "}
                          {/* Display as link */}
                        </td>
                        <td>{application.createdAt}</td>

                        <td>
                          <button
                            onClick={() =>
                              handleViewDetails(
                                application._id,
                                "job-applications"
                              )
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
