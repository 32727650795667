import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import '../../styles/Blogs/BlogForm.css';
import config from '../../config'; // Import the config file

const BlogForm = ({ onBlogAdded, author, authorEmail, authorImage }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null); // State for image file
  const [loading, setLoading] = useState(false); // Loading state
  const [submitted, setSubmitted] = useState(false); // Submission status

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('author', author); // Include author
    formData.append('authorEmail', authorEmail); // Include authorEmail
    formData.append('authorImage', authorImage); // Include authorImage
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.post(`${config.apiUrl}/blogs`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data._id) {
        onBlogAdded(response.data._id); // Pass the blog ID to the callback
        setSubmitted(true); // Set submission status to true
      }
      
      // Reset form fields
      setTitle('');
      setContent('');
      setImage(null); // Reset the image state

    } catch (error) {
      console.error('There was an error adding the blog!', error);
      alert('Failed to submit blog.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  if (submitted) {
    return (
      <div className="submission-success">
        <h1>Blog Published Successfully!</h1>
        <p>Your blog has been published. Thank you!</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="blog-form">
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Content</label>
        <ReactQuill
          value={content}
          onChange={setContent}
          className="quill-editor"
          modules={BlogForm.modules}
          formats={BlogForm.formats}
        />
      </div>
      <div className="form-group">
        <label>Cover Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          className="form-control"
        />
      </div>
      <button type="submit" className="submit-button" disabled={loading}>
        {loading ? 'Publishing...' : 'Publish'}
      </button>
    </form>
  );
};
//
// Configuring the modules for ReactQuill
BlogForm.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    [{ 'code-block': true }], // Add code block button to the toolbar
    ['clean']                                         
  ],
};

BlogForm.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
  'code-block' // Include code block in formats
];

export default BlogForm;
