import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
} from "firebase/auth";
import { auth, googleProvider } from "../../firebase/firebase";
import "../../styles/Users/RegisterUser.css"; // Ensure styles are imported

const RegisterUser = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(""); // Error state
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous error messages

    // Check if the email is part of techtrapture.com
    if (email.endsWith("@techtrapture.com")) {
      setError("Registration with a techtrapture.com email is not allowed.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await updateProfile(user, { displayName: name });

      const response = await fetch(`${config.apiUrl}/send-welcome-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: email,
          subject: "Welcome to TechTrapture!",
          name: name,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send email");
      }
      navigate("/");
    } catch (error) {
      console.error("Error registering user", error);
      setError(getFirebaseErrorMessage(error.code));
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/"); // Redirect to home page
    } catch (error) {
      console.error("Error logging in with Google", error);
      setError("An error occurred. Please try again.");
    }
  };

  const getFirebaseErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/weak-password":
        return "Weak Password !!!";
      case "auth/email-already-in-use":
        return "This email is already in use. Please use a different email.";
      case "auth/invalid-email":
        return "The email address is not valid.";
      case "auth/missing-email":
        return "Please provide an email address.";
      default:
        return "An error occurred. Please try again.";
    }
  };

  return (
    <div className="register-container">
      <div className="register-logo">
        <img
          src="https://storage.googleapis.com/bkt-static-content/logo.png"
          alt="TechTrapture Logo"
          className="logo"
        />
      </div>
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <div className="input-group">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
        </div>
        <div className="input-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit">Register</button>
      </form>
      <div className="google-login">
        <p className="continue-p">OR</p>
        <div className="google-button" onClick={handleGoogleLogin}>
          <img src={require("./googlesignup.png")} alt="Google Sign-Up" />
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;

// // Import necessary functions
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import config from "../../config";
// import {
//   createUserWithEmailAndPassword,
//   updateProfile,
//   signInWithPopup,
// } from "firebase/auth";
// import { auth, googleProvider } from "../../firebase/firebase";
// import "../../styles/Users/RegisterUser.css"; // Ensure styles are imported

// const RegisterUser = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [name, setName] = useState("");
//   const [error, setError] = useState(""); // Error state
//   const navigate = useNavigate();

//   const handleRegister = async (e) => {
//     e.preventDefault();
//     setError(""); // Clear any previous error messages
//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       const user = userCredential.user;

//       await updateProfile(user, { displayName: name });

//       const response = await fetch(`${config.apiUrl}/send-email`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           to: email,
//           subject: "Welcome to TechTrapture!",
//           name: name,
//         }),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to send email");
//       }
//       navigate("/");
//     } catch (error) {
//       console.error("Error registering user", error);
//       setError(getFirebaseErrorMessage(error.code));
//     }
//   };

//   const handleGoogleLogin = async () => {
//     try {
//       await signInWithPopup(auth, googleProvider);
//       navigate("/"); // Redirect to home page
//     } catch (error) {
//       console.error("Error logging in with Google", error);
//       setError("An error occurred. Please try again.");
//     }
//   };

//   const getFirebaseErrorMessage = (errorCode) => {
//     switch (errorCode) {
//       case "auth/weak-password":
//         return "Weak Password !!!";
//       case "auth/email-already-in-use":
//         return "This email is already in use. Please use a different email.";
//       case "auth/invalid-email":
//         return "The email address is not valid.";
//       case "auth/missing-email":
//         return "Please provide an email address.";
//       default:
//         return "An error occurred. Please try again.";
//     }
//   };

//   return (
//     <div className="register-container">
//       <div className="register-logo">
//         <img
//           src="https://storage.googleapis.com/bkt-static-content/logo.png"
//           alt="TechTrapture Logo"
//           className="logo"
//         />
//       </div>
//       <h2>Register</h2>
//       <form onSubmit={handleRegister}>
//         <div className="input-group">
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             placeholder="Name"
//             required
//           />
//         </div>
//         <div className="input-group">
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Email"
//             required
//           />
//         </div>
//         <div className="input-group">
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Password"
//             required
//           />
//         </div>
//         {error && <div className="error-message">{error}</div>}
//         <button type="submit">Register</button>
//       </form>
//       <div className="google-login">
//         <p className="continue-p">OR</p>
//         <div className="google-button" onClick={handleGoogleLogin}>
//           <img src={require("./googlesignup.png")} alt="Google Sign-Up" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RegisterUser;
