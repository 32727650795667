import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/Blogs/BlogDetail.css";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import config from "../../config";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [error, setError] = useState(null);
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Set up Firebase Auth listener
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/blogs/${id}`);
        setBlog(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching blog:", error);
        setError("Error fetching blog. Please try again later.");
      }
    };

    const fetchComments = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/blogs/${id}/comments`
        );
        setComments(response.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchBlog();
    fetchComments();
  }, [id]);

  useEffect(() => {
    if (blog) {
      document.querySelectorAll(".blog-content pre code").forEach((block) => {
        hljs.highlightBlock(block);
      });
    }
  }, [blog]);

  const handleEdit = () => {
    navigate(`/editblogs/${id}`);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${config.apiUrl}/blogs/${id}`);
      navigate("/blogs");
    } catch (error) {
      console.error("Error deleting blog:", error);
      setError("Error deleting blog. Please try again later.");
    }
  };

  const handleCommentSubmit = async () => {
    if (!user) {
      setError("You must be logged in to post a comment.");
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/blogs/${id}/comments`,
        {
          content: newComment,
          author: user.displayName || "Anonymous",
          authorEmail: user.email,
        }
      );
      setComments(response.data);
      setNewComment("");
    } catch (error) {
      console.error("Error posting comment:", error);
      setError("Error posting comment. Please try again later.");
    }
  };

  const handleEditComment = (comment) => {
    setCommentToEdit(comment);
  };

  const handleUpdateComment = async () => {
    try {
      const response = await axios.put(
        `${config.apiUrl}/blogs/${id}/comments/${commentToEdit._id}`,
        {
          content: commentToEdit.content,
        }
      );
      setComments(response.data);
      setCommentToEdit(null);
    } catch (error) {
      console.error("Error updating comment:", error);
      setError("Error updating comment. Please try again later.");
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axios.delete(`${config.apiUrl}/blogs/${id}/comments/${commentId}`);
      setComments(comments.filter((comment) => comment._id !== commentId));
    } catch (error) {
      console.error("Error deleting comment:", error);
      setError("Error deleting comment. Please try again later.");
    }
  };

  return (
    <div className="blog-detail-container">
      {error && <p className="error-message">{error}</p>}
      {blog ? (
        <>
          <h1 className="blog-title">{blog.title}</h1>
          <div className="author-info">
            {blog.authorImage ? (
              <img
                src={blog.authorImage}
                alt={blog.author}
                className="author-image"
              />
            ) : (
              <img
                src="/path/to/default/image.jpg"
                alt="Default"
                className="author-image"
              />
            )}
            <div className="author-details">
              <p className="author-name">{blog.author}</p>
              <p className="created-at">
                Published on {new Date(blog.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
          {blog.image && (
            <img
              src={blog.image}
              alt={blog.title}
              className="blog-detail-blog-image"
            />
          )}
          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />

          {/* Comments Section */}
          <div className="comments-section">
            <h3>Comments</h3>
            {comments.length > 0 ? (
              comments.map((comment) => (
                <div key={comment._id} className="comment">
                  <p className="comment-author">
                    {comment.author}{" "}
                    <span className="comment-date">
                      ({new Date(comment.createdAt).toLocaleDateString()})
                    </span>
                  </p>
                  {commentToEdit && commentToEdit._id === comment._id ? (
                    <div className="edit-comment">
                      <textarea
                        value={commentToEdit.content}
                        onChange={(e) =>
                          setCommentToEdit({
                            ...commentToEdit,
                            content: e.target.value,
                          })
                        }
                      />
                      <button onClick={handleUpdateComment}>Update</button>
                      <button onClick={() => setCommentToEdit(null)}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <>
                      <p className="comment-content">{comment.content}</p>
                      {user &&
                        (comment.authorEmail === user.email ||
                          user.email.endsWith("@techtrapture.com")) && (
                          <div className="comment-actions">
                            <button
                              onClick={() => handleEditComment(comment)}
                              className="comment-edit-button"
                            >
                              Edit Comment
                            </button>
                            <button
                              onClick={() => handleDeleteComment(comment._id)}
                              className="comment-delete-button"
                            >
                              Delete Comment
                            </button>
                          </div>
                        )}
                    </>
                  )}
                </div>
              ))
            ) : (
              <p>No comments yet. Be the first to comment!</p>
            )}

            {user ? (
              <div className="comment-form">
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add your comment..."
                />
                <button onClick={handleCommentSubmit}>Post Comment</button>
              </div>
            ) : (
              <div className="login-prompt">
                <p style={{ color: "#ff0000" }}>
                  You must be logged in to add a comment.
                </p>
                <button onClick={() => navigate("/login")}>Add Comment</button>
              </div>
            )}
          </div>

          {user &&
            (blog.authorEmail === user.email ||
              user.email.endsWith("@techtrapture.com")) && (
              <div className="blog-actions">
                <button onClick={handleEdit} className="blog-edit-button">
                  Edit Blog
                </button>
                <button onClick={handleDelete} className="blog-delete-button">
                  Delete Blog
                </button>
              </div>
            )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BlogDetail;
