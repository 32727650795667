import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LoginActivity.css"; // Import CSS for styling if needed
import config from "../../config"; // Adjust the path if needed

const LoginActivity = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage] = useState(10); // Number of activities to display per page
  const [filter, setFilter] = useState("");
  const [filteredActivities, setFilteredActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/loginactivity`);
        setActivities(response.data);
        setFilteredActivities(response.data); // Initially show all activities
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch login activities.");
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    // Filter activities based on the search term
    const filtered = activities.filter(
      (activity) =>
        (activity.email || "").toLowerCase().includes(filter.toLowerCase()) ||
        (activity.username || "")
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        (activity.loginMethod || "")
          .toLowerCase()
          .includes(filter.toLowerCase())
    );
    setFilteredActivities(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [filter, activities]);

  // Get current activities for the current page
  const indexOfLastActivity = currentPage * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentActivities = filteredActivities.slice(
    indexOfFirstActivity,
    indexOfLastActivity
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredActivities.length / activitiesPerPage);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="login-activity-container">
      <h1>Login Activities</h1>
      <div className="filter-container">
        <input
          type="text"
          placeholder="Filter by email, username, or login method..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="login-activity-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Email</th>
            <th>Username</th>
            <th>Login Method</th>
            <th>Logged In At</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {currentActivities.map((activity) => (
            <tr key={activity._id}>
              <td>{activity.userId}</td>
              <td>{activity.email}</td>
              <td>{activity.username}</td>
              <td>{activity.loginMethod}</td>
              <td>{new Date(activity.LoggedInAt).toLocaleString()}</td>
              <td>{activity.isActive ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LoginActivity;
