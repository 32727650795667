import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import '../../styles/Documents/DocumentDetails.css'; // Add necessary styles
import config from '../../config'; // Import the config file
import { getAuth } from 'firebase/auth';
import icons from '../../icons/icons'; // Adjust path to import icons
import axios from 'axios';

const DocumentDetail = () => {
  const { id } = useParams(); // Get the document ID from URL parameters
  const [document, setDocument] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate(); // For navigation

  // Define the getDocumentById function
  const getDocumentById = async (id) => {
    try {
      const response = await fetch(`${config.apiUrl}/documents/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const doc = await response.json();
      return doc;
    } catch (error) {
      console.error('Error fetching document by ID:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchDocument = async () => {
      const doc = await getDocumentById(id);
      setDocument(doc);
    };
    fetchDocument();
  }, [id]);

  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
    }
  }, [user]);

  const handleDelete = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/documents/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete document.');
      }

      // Log the delete action
      await axios.post(`${config.apiUrl}/logging`, {
        type: 'delete',
        message: 'User deleted a document',
        details: { email: user.email, title: document.title }
      });

      // Redirect to the documents list after deletion
      navigate('/documents');
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleViewDocument = async () => {
    if (!user) {
      // User is not logged in
      alert('You need to be logged in to view this document.');
      navigate('/login'); // Redirect to login page
      return;
    }

    try {
      // Open the document URL in a new tab
      window.open(document.url, '_blank', 'noopener,noreferrer');
  
      // Send a logging request
      const response = await axios.post(`${config.apiUrl}/logging`, {
        type: 'view',
        message: 'User viewed a document',
        details: { email: user.email, title: document.title }
      });
  
      // Log success message
      console.log('Logging successful', response.data);
    } catch (error) {
      // Log error details
      console.error('Error logging view action:', error.response ? error.response.data : error.message);
    }
  };
  
  if (!document) return <div>Loading...</div>;

  return (
    <div className="document-detail-container">
      <div className="document-icon">
        {/* Replace with appropriate icon based on file type */}
        <img src={icons[document.fileType] || icons.default} alt="Document Icon" />
      </div>
      <h1>{document.title}</h1>
      <p>{document.description}</p>
      <div className="action-buttons">
        <button onClick={handleViewDocument} className="view-document-button">View</button>
        {userEmail && userEmail.endsWith('@techtrapture.com') && (
          <button onClick={handleDelete} className="delete-document-button">Delete</button>
        )}
      </div>
      <Link to="/documents" className="back-link">Back to Documents</Link>
    </div>
  );
};

export default DocumentDetail;
