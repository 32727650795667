import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config"; // Import the config file
import "../../styles/Courses/Courses.css"; // Import the CSS file for additional styling
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom"; // Ensure Link is imported

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/courses`);
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchUser = () => {
      const user = auth.currentUser;
      if (user) {
        setUserEmail(user.email);
      }
      setLoading(false);
    };

    fetchUser();
  }, [auth]);

  if (loading) {
    return <p>Loading...</p>;
  }

  // Helper function to extract the playlist ID from the URL
  const getPlaylistId = (url) => {
    if (!url) return null; // Ensure url is not undefined or null
    const regex = /list=([a-zA-Z0-9_-]+)/; // Regex to capture the playlist ID
    const match = url.match(regex);
    return match ? match[1] : null; // Return the playlist ID or null if not found
  };

  return (
    <div className="courses-container">
      {userEmail && userEmail.endsWith("@techtrapture.com") && (
        <Link to="/addcourse" className="add-course-link">
          Add Course
        </Link>
      )}
      <div className="course-grid">
        {courses.length > 0 ? (
          courses.map((course) => {
            const playlistId = getPlaylistId(course.playlistURL); // Changed to playlistURL
            return (
              <div
                key={course._id}
                className="course-card course-card-animation"
              >
                <h3 className="course-title">{course.title}</h3>
                {playlistId ? (
                  <div>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/videoseries?list=${playlistId}`}
                      title={course.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                      style={{
                        width: "100%",
                        height: "315px",
                        borderRadius: "8px",
                        marginTop: "10px",
                      }}
                    ></iframe>
                    <a
                      href={course.playlistURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="explore-now-button"
                    >
                      Explore Now
                    </a>
                  </div>
                ) : (
                  <p>Playlist not available for this course.</p>
                )}
              </div>
            );
          })
        ) : (
          <p>No courses available.</p>
        )}
      </div>
    </div>
  );
};

export default Courses;
