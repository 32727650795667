import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider } from "../../firebase/firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import "../../styles/Users/Login.css"; // Add your styles
import config from "../../config";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // Error state
  const [resetEmail, setResetEmail] = useState(""); // State for password reset email
  const [resetSuccess, setResetSuccess] = useState(""); // State for reset email success message
  const [showReset, setShowReset] = useState(false); // State to toggle between login and reset forms
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous error messages
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Fetch username from user profile or use a default value
      const displayName = user.displayName || "Unknown User"; // Handle if user doesn't have a displayName

      // Log the login activity in your backend
      try {
        await fetch(`${config.apiUrl}/logLoginActivity`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.uid, // Firebase user ID
            email: user.email,
            username: displayName, // Send username
            loginMethod: "Email", // Specify login method
          }),
        });
      } catch (loggingError) {
        console.error("Error logging login activity", loggingError);
        // Optionally, you can show a message to the user here if desired
      }

      navigate("/"); // Redirect to home page
    } catch (error) {
      console.error("Error logging in with email and password", error);
      setError(getFirebaseErrorMessage(error.code)); // Set custom error message
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Fetch username from user profile or use a default value
      const displayName = user.displayName || "Unknown User"; // Handle if user doesn't have a displayName

      // Log the login activity in your backend
      try {
        await fetch(`${config.apiUrl}/logLoginActivity`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.uid, // Firebase user ID
            email: user.email,
            username: displayName, // Send username
            loginMethod: "Email", // Specify login method
          }),
        });
      } catch (loggingError) {
        console.error("Error logging login activity", loggingError);
        // Optionally, you can show a message to the user here if desired
      }
      navigate("/"); // Redirect to home page
    } catch (error) {
      console.error("Error logging in with Google", error);
      setError("An error occurred. Please try again."); // General error message for Google login
    }
  };

  const handlePasswordReset = async () => {
    if (!resetEmail) {
      setError("Please enter your email address.");
      return;
    }

    try {
      // Check if the email exists
      const signInMethods = await fetchSignInMethodsForEmail(auth, resetEmail);
      if (signInMethods.length === 0) {
        setError("No user found with this email address.");
        return;
      }

      // Send the password reset email
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess("Password reset email sent! Check your inbox.");
      setResetEmail("");
      setError("");
    } catch (error) {
      console.error("Error sending password reset email", error);
      setError("Error sending password reset email. Please try again.");
    }
  };

  const getFirebaseErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "The email address is not valid.";
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/wrong-password":
        return "Incorrect password. Please try again.";
      case "auth/invalid-credential":
        return "Invalid credentials. Please check your email and password.";
      case "auth/user-disabled":
        return "User is disabled. Please contact Admin";
      default:
        return "An error occurred. Please try again.";
    }
  };

  return (
    <div className="login-container">
      {!showReset ? ( // If showReset is false, show the login form
        <>
          <div className="register-logo">
            <img
              src="https://storage.googleapis.com/bkt-static-content/logo.png"
              alt="TechTrapture Logo"
              className="logo"
            />
          </div>
          <h2>Login</h2>
          <form onSubmit={handleEmailLogin}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            {error && <div className="error-message">{error}</div>}{" "}
            {/* Display custom error message */}
            <button type="submit">Login</button>
          </form>
          <p className="continue-p">OR</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleGoogleLogin}
          >
            <img
              src={require("./google.png")}
              alt="Google Sign-In"
              width="60%"
            />
          </div>
          <p className="forgot-password-link">
            <a href="#!" onClick={() => setShowReset(true)}>
              Forgot Password?
            </a>
          </p>
        </>
      ) : (
        // If showReset is true, show the password reset form
        <div className="modal-content">
          <h2>Reset Password</h2>
          <input
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <button onClick={handlePasswordReset}>Send Reset Email</button>
          {resetSuccess && (
            <div className="success-message">{resetSuccess}</div>
          )}
          {error && <div className="error-message">{error}</div>}
          <p className="forgot-password-link">
            <a href="#!" onClick={() => setShowReset(false)}>
              Back to Login
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default Login;

// Without password reset
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { auth, googleProvider } from '../../firebase/firebase';
// import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
// import '../../styles/Users/Login.css'; // Add your styles

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState(''); // Error state
//   const navigate = useNavigate();

//   const handleEmailLogin = async (e) => {
//     e.preventDefault();
//     setError(''); // Clear any previous error messages
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       navigate('/'); // Redirect to home page
//     } catch (error) {
//       console.error("Error logging in with email and password", error);
//       setError(getFirebaseErrorMessage(error.code)); // Set custom error message
//     }
//   };

//   const handleGoogleLogin = async () => {
//     try {
//       await signInWithPopup(auth, googleProvider);
//       navigate('/'); // Redirect to home page
//     } catch (error) {
//       console.error("Error logging in with Google", error);
//       setError('An error occurred. Please try again.'); // General error message for Google login
//     }
//   };
//   const getFirebaseErrorMessage = (errorCode) => {
//     switch (errorCode) {
//       case 'auth/invalid-email':
//         return 'The email address is not valid.';
//       case 'auth/user-not-found':
//         return 'No user found with this email.';
//       case 'auth/wrong-password':
//         return 'Incorrect password. Please try again.';
//       case 'auth/invalid-credential':
//         return 'Invalid credentials. Please check your email and password.';
//       case 'auth/user-disabled':
//           return 'User is disabled. Please contact Admin';
//       default:
//         return 'An error occurred. Please try again.';
//     }
//   };

//   return (
//     <div className="login-container">
//       <div className="register-logo">
//         <img src="https://storage.googleapis.com/bkt-static-content/logo.png" alt="TechTrapture Logo" className="logo" />
//       </div>
//       <h2>Login</h2>
//       <form onSubmit={handleEmailLogin}>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Email"
//           required
//         />
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Password"
//           required
//         />
//         {error && <div className="error-message">{error}</div>} {/* Display custom error message */}
//         <button type="submit">Login</button>
//       </form>
//       <p className="continue-p">OR</p>
//       <div
//         style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
//         onClick={handleGoogleLogin}
//       >
//         <img src={require('./google.png')} alt="Google Sign-In" width="60%" />
//       </div>
//     </div>
//   );
// };

// export default Login;
