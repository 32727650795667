import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/JobApplication/JobApplicationDetails.css'; // Add necessary styles
import config from '../../config'; // Import the config file

const JobApplicationDetails = () => {
  const { id } = useParams(); // Get the application ID from the URL
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/job-applications/${id}`);
        setApplication(response.data);
      } catch (err) {
        console.error('Error fetching job application details:', err);
        setError('Failed to fetch job application details.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplicationDetails();
  }, [id]);

  if (loading) return <p className="loading">Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="job-application-details-container">
      {application ? (
        <div className="application-details-card">
          <h1 className="title">Job Application Details</h1>
          <div className="details-section">
            <h2 className="section-title">Personal Information</h2>
            <p><strong>Full Name:</strong> {application.fullName}</p>
            <p><strong>Email:</strong> {application.email}</p>
            <p><strong>Phone Number:</strong> {application.phoneNumber}</p>
          </div>
          <div className="details-section">
            <h2 className="section-title">Social Links</h2>
            {application.socialLinks.length > 0 ? (
              <ul className="social-links-list">
                {application.socialLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No social links provided.</p>
            )}
          </div>
          <div className="details-section">
            <h2 className="section-title">Resume</h2>
            {application.resume ? (
              <a href={application.resume} target="_blank" rel="noopener noreferrer" className="resume-link">View Resume</a>
            ) : (
              <p>No resume uploaded</p>
            )}
          </div>
          <div className="details-section">
            <h2 className="section-title">Message</h2>
            <p>{application.message}</p>
          </div>
          <div className="details-section">
            <h2 className="section-title">Date Applied</h2>
            <p>{new Date(application.createdAt).toLocaleDateString()}</p>
          </div>
        </div>
      ) : (
        <p>No details available.</p>
      )}
    </div>
  );
};

export default JobApplicationDetails;
