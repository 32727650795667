import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/firebase";
import "../../styles/common/Header.css";

const Header = () => {
  const [user] = useAuthState(auth);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const isTechTraptureUser = user?.email?.endsWith("@techtrapture.com");

  return (
    <header className="header">
      <div className="header-left">
        <img
          src="https://storage.googleapis.com/bkt-static-content/logo.png"
          alt="Icon"
          className="header-icon"
        />
        <h1 className="header-title">TechTrapture</h1>
      </div>
      <button className="mobile-nav-toggle" onClick={toggleNav}>
        ☰
      </button>
      <nav className={`header-nav ${isNavOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/" onClick={closeNav}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/blogs" onClick={closeNav}>
              Blogs
            </Link>
          </li>
          <li>
            <Link to="/courses" onClick={closeNav}>
              Courses
            </Link>
          </li>
          <li>
            <Link to="/documents" onClick={closeNav}>
              Documents
            </Link>
          </li>
          <li>
            <Link to="/work-with-us" onClick={closeNav}>
              Work with us
            </Link>
          </li>
          {user && isTechTraptureUser && (
            <li className="dropdown">
              <span className="dropdown-toggle" onClick={toggleDropdown}>
                Admin
              </span>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/admin" onClick={closeDropdown}>
                      Admin Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/job-applications" onClick={closeDropdown}>
                      Job Applications
                    </Link>
                  </li>
                  <li>
                    <Link to="/enquiry" onClick={closeDropdown}>
                      Enquiry
                    </Link>
                  </li>
                  <li>
                    <Link to="/auditlogs" onClick={closeDropdown}>
                      Audit Logs
                    </Link>
                  </li>
                  <li>
                    <Link to="/loginactivity" onClick={closeDropdown}>
                      Session Logs
                    </Link>
                  </li>
                  <li>
                    <Link to="/sendemail" onClick={closeDropdown}>
                      Send Email
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}
          {user ? (
            <>
              <li className="user-info">
                <img
                  src={
                    user.photoURL ||
                    "https://storage.googleapis.com/bkt-static-content/default-profile.png"
                  }
                  alt="Profile"
                  className="profile-pic"
                />
                <span className="user-name">{user.displayName}</span>
              </li>
              <li>
                <Link
                  to="/logout"
                  className="btn logout-btn"
                  onClick={closeNav}
                >
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login" className="btn" onClick={closeNav}>
                  Login
                </Link>
              </li>
              <li>
                <Link to="/register" className="btn" onClick={closeNav}>
                  Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
