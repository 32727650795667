import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import axios from "axios";
import "../../styles/JobApplication/WorkWithUs.css"; // Import the CSS file
import config from "../../config"; // Import the config file

const WorkWithUs = () => {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [socialLinks, setSocialLinks] = useState([""]);
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [submitted, setSubmitted] = useState(false); // Submission status
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
    }
  }, [user]);

  const handleSocialLinkChange = (index, event) => {
    const newSocialLinks = [...socialLinks];
    newSocialLinks[index] = event.target.value;
    setSocialLinks(newSocialLinks);
  };

  const addSocialLink = () => {
    setSocialLinks([...socialLinks, ""]);
  };

  const handleFileChange = (event) => {
    setResume(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", userEmail);
    formData.append("phoneNumber", phoneNumber);
    formData.append("socialLinks", JSON.stringify(socialLinks)); // Serialize socialLinks as JSON
    formData.append("resume", resume);
    formData.append("message", message);

    try {
      await axios.post(`${config.apiUrl}/job-applications`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSubmitted(true); // Set submission status to true
      await axios.post(
        `${config.apiUrl}/job-application-reply-email`,
        { email: userEmail, fullName: fullName }, // Send email and fullName in JSON format
        {
          headers: {
            "Content-Type": "application/json", // Use JSON for this request
          },
        }
      );
    } catch (error) {
      console.error("Error submitting application:", error);
      alert("Failed to submit application.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  if (submitted) {
    return (
      <div className="submission-success">
        <h1>Your Application Submitted Successfully!</h1>
        <p>Thank you for applying. We will get back to you soon.</p>
      </div>
    );
  }

  return (
    <div className="main-container">
      {/* <h1 className="page-title">Work with Us</h1> */}
      <form className="application-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <h1>Join us !</h1>

          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            placeholder="Enter your full name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            placeholder="Enter your phone number"
          />
        </div>
        <div className="form-group">
          <label>Social Links:</label>
          {socialLinks.map((link, index) => (
            <input
              key={index}
              type="url"
              value={link}
              onChange={(e) => handleSocialLinkChange(index, e)}
              placeholder="Enter a social link"
            />
          ))}
          <button
            type="button"
            className="add-link-button"
            onClick={addSocialLink}
          >
            Add More
          </button>
        </div>
        <div className="form-group">
          <label htmlFor="resume">Resume Upload:</label>
          <input
            type="file"
            id="resume"
            accept=".pdf, .doc, .docx"
            onChange={handleFileChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            placeholder="Enter your message"
          ></textarea>
        </div>
        <button type="submit" className="submit-button">
          {loading ? "Submitting your application..." : "Submit"}
        </button>
      </form>
      <div className="image-container-w">
        <img
          src="https://storage.googleapis.com/bkt-static-content/contactus.png"
          alt="Contact Us"
        />
      </div>
    </div>
  );
};

export default WorkWithUs;
