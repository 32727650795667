import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import config from "../../config"; // Import the config file
import { getAuth } from "firebase/auth"; // Import Firebase auth

const defaultAuthorImage = "/default-profile.png"; // Path to default image in public folder
const EditBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null); // State for new image file
  const [error, setError] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/blogs/${id}`);
        setTitle(response.data.title);
        setContent(response.data.content);
        // Fetch existing blog data but not modifying author-related fields
      } catch (error) {
        console.error("Error fetching blog:", error);
        setError("Error fetching blog. Please try again later.");
      }
    };

    fetchBlog();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("authorEmail", user.email);

    if (image) {
      formData.append("image", image); // Add new image if provided
    }

    try {
      const response = await axios.put(
        `${config.apiUrl}/blogs/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        navigate(`/blogs/${id}`); // Redirect to blog detail page after update
      } else {
        console.error("Unexpected response status:", response.status);
        setError("Unexpected response status. Please try again later.");
      }
    } catch (error) {
      console.error(
        "Error updating blog:",
        error.response ? error.response.data : error.message
      );
      setError("Error updating blog. Please try again later.");
    }
  };

  return (
    <div className="edit-blog-container">
      <h1>Edit Blog</h1>
      <form onSubmit={handleSubmit} className="blog-form">
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            className="quill-editor"
            modules={EditBlog.modules}
            formats={EditBlog.formats}
          />
        </div>
        <div className="form-group">
          <label>Cover Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="form-control"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-button">
          Update Blog
        </button>
      </form>
    </div>
  );
};

EditBlog.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
    [{ "code-block": true }],
  ],
};

EditBlog.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code-block",
];

export default EditBlog;
