import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AuditLogs.css"; // Import CSS for styling if needed
import config from "../../config";

const AuditLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10); // Number of logs to display per page
  const [filter, setFilter] = useState("");
  const [filteredLogs, setFilteredLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/auditlogs`);
        setLogs(response.data);
        setFilteredLogs(response.data); // Initially show all logs
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch activity logs.");
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  useEffect(() => {
    // Filter logs based on the search term
    const filtered = logs.filter((log) =>
      (log.activity || "").toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredLogs(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [filter, logs]);

  // Get current logs for the current page
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

  // Calculate total pages
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="audit-log-container">
      <h1>Audit Logs</h1>
      <div className="filter-container">
        <input
          type="text"
          placeholder="Filter by activity..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="audit-log-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Activity</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {currentLogs.map((log) => (
            <tr key={log._id}>
              <td>{log.user}</td>
              <td>{log.activity || "N/A"}</td> {/* Provide fallback value */}
              <td>{new Date(log.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AuditLog;
