import React, { useState } from "react";
import axios from "axios";
import "../../styles/Courses/AddCourse.css"; // Import CSS for styling
import config from "../../config"; // Import the config file

function AddCourse() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [playlistURL, setPlaylistURL] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // URL validation
    const urlPattern = /^https:\/\/www\.youtube\.com\/playlist\?list=[\w-]+$/;
    if (!urlPattern.test(playlistURL)) {
      setError("Please enter a valid YouTube playlist URL.");
      return;
    }

    setError(""); // Clear previous errors

    try {
      // POST request to add the course
      const response = await axios.post(`${config.apiUrl}/courses`, {
        title,
        description,
        playlistURL,
      });

      if (response.status === 201) {
        setSuccess("Course added successfully!");
        setTitle("");
        setDescription("");
        setPlaylistURL("");
      }
    } catch (err) {
      setError("Failed to add course. Please try again.");
      console.log(err);
    }
  };

  return (
    <div className="add-course-container">
      <h2>Add a New Course</h2>
      <form onSubmit={handleSubmit} className="add-course-form">
        <div className="form-group">
          <label>Course Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description (Optional)</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Playlist URL</label>
          <input
            type="url"
            value={playlistURL}
            onChange={(e) => setPlaylistURL(e.target.value)}
            placeholder="https://www.youtube.com/playlist?list=..."
            required
          />
        </div>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}

        <button type="submit" className="submit-button">
          Add Course
        </button>
      </form>
    </div>
  );
}

export default AddCourse;
