import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import AdminAccessDenied from "../../pages/Admin/AdminAccessDenied"; // Adjust the import path if needed

const PrivateRoute = ({
  element: Component,
  isAdminRoute = false,
  ...rest
}) => {
  const { isAuthenticated, user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isAdminRoute) {
    if (user && user.email.endsWith("@techtrapture.com")) {
      return <Component {...rest} />;
    } else {
      return <AdminAccessDenied />;
    }
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
