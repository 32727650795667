import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

//// Prod firebase
const firebaseConfig = {
  apiKey: "AIzaSyA_Uj-4tcc72Gf7DCBNuXPY6KnMmcGk5II",
  // authDomain: "tt-prd-001.firebaseapp.com",
  authDomain: "auth.techtrapture.com",
  projectId: "tt-prd-001",
  storageBucket: "tt-prd-001.appspot.com",
  messagingSenderId: "891210905713",
  appId: "1:891210905713:web:f1b5230ab451f5856c03d0",
};

/// Dev firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyCRpNGqJDjWywaV-BHU0Mism1fnXu1yNJo",
//   authDomain: "tt-dev-001.firebaseapp.com",
//   projectId: "tt-dev-001",
//   storageBucket: "tt-dev-001.appspot.com",
//   messagingSenderId: "657207815175",
//   appId: "1:657207815175:web:f9f37e539993f9825c4ea2",
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
