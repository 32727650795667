import React, { useState } from "react";
import axios from "axios";
import "../../styles/Enquiry/ContactUs.css"; // Assuming you have a CSS file for styling
import config from "../../config"; // Import the config file

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    category: "",
  });
  const [submitted, setSubmitted] = useState(false); // Added state for submission status
  const [loading, setLoading] = useState(false); // Added state for loading status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      await axios.post(`${config.apiUrl}/enquiry`, formData); // Send data to backend

      // Send automatic reply email
      await axios.post(
        `${config.apiUrl}/contact-us-reply-email`,
        { email: formData.email, name: formData.name },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSubmitted(true); // Set submission status to true
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
        category: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to send message.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  if (submitted) {
    return (
      <div className="submission-success">
        <h1>Your message has been sent successfully!</h1>
        <p>Thank you for reaching out. We will get back to you soon.</p>
      </div>
    );
  }

  return (
    <div className="contact-us-container">
      <div className="contact-form-container">
        <h1>Contact Us</h1>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="enquiry">Enquiry</option>
              <option value="support">Support</option>
              <option value="feedback">Feedback</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
            />
          </div>
          <button type="submit" className="submit-button">
            {loading ? "Submitting..." : "Send"}
          </button>
        </form>
      </div>
      <div className="image-container">
        <img
          src="https://storage.googleapis.com/bkt-static-content/contactus.png"
          alt="Contact Us"
        />
      </div>
    </div>
  );
};

export default ContactUs;
