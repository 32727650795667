// src/config.js

// //// App engine prod
const config = {
  apiUrl: "https://backend-service-dot-tt-prd-001.el.r.appspot.com/api",
};
export default config;

// //App engine dev config

// const config = {
//   apiUrl: "https://site-backend-dot-tt-dev-001.uc.r.appspot.com/api",
// };
// export default config;

//Local settings
// const config = {
//   apiUrl: "http://localhost:5000/api", // Use default if not set
// };
// export default config;
