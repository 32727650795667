import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import { Link } from "react-router-dom"; // Import Link for navigation
import "../../styles/Enquiry/Enquiry.css"; // Import the CSS file for styling
import config from "../../config"; // Import the config file

const Enquiry = () => {
  const [enquiries, setEnquiries] = useState([]); // State to hold the list of enquiries
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    // Fetch all enquiries when the component mounts
    const fetchEnquiries = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/enquiry`);
        setEnquiries(response.data); // Set the enquiries data
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        setError("Failed to fetch enquiries.");
        setLoading(false);
      }
    };

    fetchEnquiries();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>All Enquiries</h1>
      <div className="enquiry-list">
        {enquiries.map((enquiry) => (
          <div key={enquiry._id} className="enquiry-item">
            <h3>{enquiry.subject}</h3>
            <p>
              <strong>Name:</strong> {enquiry.name}
            </p>
            <p>
              <strong>Email:</strong> {enquiry.email}
            </p>
            <p>
              <strong>Category:</strong> {enquiry.category}
            </p>
            <p>
              <strong>Created At:</strong>{" "}
              {new Date(enquiry.createdAt).toLocaleString()}
            </p>
            <Link to={`/enquiry/${enquiry._id}`} className="details-link">
              View Details
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Enquiry;
