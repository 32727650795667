import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <img
          src="https://storage.googleapis.com/bkt-static-content/logo.png"
          alt="Icon"
          className="footer-logo"
        />
        <p>© 2024 TechTrapture. All Rights Reserved.</p>
      </div>
      <div className="footer-center">
        <div className="footer-section">
          <h4>Quick Links</h4>
          <nav className="footer-nav">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/contact-us">Contact Us</a>
          </nav>
        </div>
        <div className="footer-section">
          <h4>Resources</h4>
          <nav className="footer-nav">
            <a href="/courses">Courses</a>
            <a href="/documents">Documents</a>
            <a href="/blogs">Blogs</a>
          </nav>
        </div>
        <div className="footer-section">
          <h4>Help</h4>
          <nav className="footer-nav">
            <a href="/contact-us">Contact Us</a>
            <a href="/contact-us">Support</a>
            <a href="/contact-us">Feedback</a>
          </nav>
        </div>
      </div>
      <div className="footer-right">
        <h4>Connect</h4>
        <div className="social-icons">
          <a
            href="https://www.instagram.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCMNZFAhQSB314E_MG3IbTXQ?sub_confirmation=1
"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-youtube"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/vishal-bulbule/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://vishalbulbule.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-medium"></i>
          </a>
        </div>
        <p></p>
        <div className="contact-info">
          <h4>Mail us on</h4>
          <a href="mailto:support@techtrapture.com" className="email-link">
            <i className="fas fa-envelope"></i> support@techtrapture.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
