import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/Blogs/Blogs.css";
import { truncateContent } from "../../utils"; // Import the truncate function
import config from "../../config"; // Import the config file

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const blogsPerPage = 10; // Number of blogs per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/blogs`);
        const sortedBlogs = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setBlogs(sortedBlogs);
        setError(null);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Error fetching blogs. Please try again later.");
      }
    };
    fetchBlogs();
  }, []);

  // Calculate current blogs to display based on the page
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  // Total pages calculation
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  return (
    <>
      <button className="add-blog-button" onClick={() => navigate("/addblogs")}>
        Add Blog
      </button>
      {error && <p className="error-message">{error}</p>}

      <div className="blogs-list">
        {currentBlogs.map((blog) => (
          <Link key={blog._id} to={`/blogs/${blog._id}`} className="blog-item">
            <div className="blog-content">
              <h2>{blog.title}</h2>
              <div className="author-info">
                {blog.authorImage && (
                  <img
                    src={blog.authorImage}
                    alt={blog.author}
                    className="author-image"
                  />
                )}
                <div className="author-details">
                  <p className="author-name">{blog.author}</p>
                  <p className="created-at">
                    Published on {new Date(blog.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="blog-preview">
                {truncateContent(blog.content, 350)}{" "}
              </div>
            </div>
            {blog.image && (
              <img
                src={blog.image}
                alt={blog.title}
                className="blog-container-blog-image"
              />
            )}
          </Link>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="blog-pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </>
  );
};

export default Blogs;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import "../../styles/Blogs/Blogs.css";
// import { truncateContent } from "../../utils"; // Import the truncate function
// import config from "../../config"; // Import the config file

// const Blogs = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get(`${config.apiUrl}/blogs`);

//         // Sort blogs by createdAt in descending order (newer to older)
//         const sortedBlogs = response.data.sort(
//           (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
//         );

//         setBlogs(sortedBlogs);
//         setError(null); // Clear previous errors
//       } catch (error) {
//         console.error("Error fetching blogs:", error); // Log the full error
//         setError("Error fetching blogs. Please try again later.");
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <>
//       <button className="add-blog-button" onClick={() => navigate("/addblogs")}>
//         Add Blog
//       </button>
//       {error && <p className="error-message">{error}</p>}
//       <div className="blogs-list">
//         {blogs.map((blog) => (
//           <Link key={blog._id} to={`/blogs/${blog._id}`} className="blog-item">
//             <div className="blog-content">
//               <h2>{blog.title}</h2>
//               <div className="author-info">
//                 {blog.authorImage && (
//                   <img
//                     src={blog.authorImage}
//                     alt={blog.author}
//                     className="author-image"
//                   />
//                 )}
//                 <div className="author-details">
//                   <p className="author-name">{blog.author}</p>
//                   <p className="created-at">
//                     Published on {new Date(blog.createdAt).toLocaleDateString()}
//                   </p>
//                 </div>
//               </div>
//               <div className="blog-preview">
//                 {truncateContent(blog.content, 350)}{" "}
//                 {/* Show preview with 350 characters */}
//               </div>
//             </div>
//             {blog.image && (
//               <img
//                 src={blog.image}
//                 alt={blog.title}
//                 className="blog-container-blog-image"
//               />
//             )}
//           </Link>
//         ))}
//       </div>
//     </>
//   );
// };

// export default Blogs;
