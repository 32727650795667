import React, { useState, useEffect } from "react";
import "./Home.css";
import config from "../config"; // Import the config file
import icons from "../icons/icons"; // Adjust path to import icons
import { Link } from "react-router-dom";

const Home = () => {
  const [blogs, setBlogs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch all blogs from the backend
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/blogs`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const shuffledBlogs = data.sort(() => 0.5 - Math.random());
        setBlogs(shuffledBlogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    // Fetch all courses from the backend
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/courses`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const shuffledCourses = data.sort(() => 0.5 - Math.random());
        setCourses(shuffledCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    // Fetch all documents from the backend
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/documents`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const shuffledDocuments = data.sort(() => 0.5 - Math.random());
        setDocuments(shuffledDocuments);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    // Fetch all data
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchBlogs(), fetchCourses(), fetchDocuments()]);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Helper function to extract the playlist ID from the URL
  const getPlaylistId = (url) => {
    if (!url) return null;
    const regex = /list=([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    <div className="home">
      {/* Hero Section */}
      <div className="hero">
        <div className="hero-content">
          <h1>Welcome to TechTrapture Your Cloud & AI Resource Hub</h1>
          <p>Explore the latest in Cloud computing and AI innovations.</p>
          <p>
            As a Cloud Architect and AI enthusiast, I've created this platform
            to provide you with a comprehensive set of resources to accelerate
            your learning and professional growth. Here, you'll find a curated
            collection of top-notch courses, insightful blogs, essential
            documents, and interactive hands-on labs designed to help you master
            the latest technologies in cloud computing and artificial
            intelligence.
          </p>
          <p>
            Whether you’re a beginner seeking to understand the fundamentals or
            an experienced professional looking to stay ahead of the curve, this
            site offers valuable content and practical tools to support your
            journey. Dive in and explore the resources tailored to elevate your
            expertise and empower you to tackle the challenges of tomorrow’s
            tech landscape.
          </p>
        </div>
      </div>
      {/* Blogs Section */}
      <section className="blogs">
        <h2>All Blogs</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="blog-grid">
            {blogs.length > 0 ? (
              blogs.slice(0, 3).map((blog) => (
                <div
                  key={blog._id}
                  className="small-blog-card blog-card-animation"
                >
                  <a href={`/blogs/${blog._id}`} className="blog-link">
                    <img
                      className="small-blog-image"
                      src={
                        blog.image ||
                        "https://storage.googleapis.com/bkt-static-content/logo.png"
                      }
                      alt={blog.title}
                    />
                    <div className="small-blog-content">
                      <h3>{blog.title}</h3>
                      <p></p>
                      <p></p>
                    </div>
                    <button className="read-now-button">Read Now</button>
                  </a>
                </div>
              ))
            ) : (
              <p>No blogs available.</p>
            )}
          </div>
        )}
      </section>

      {/* Top Courses Section */}
      <section className="top-courses">
        <h2>Top Courses</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="home-course-grid">
            {courses.length > 0 ? (
              courses.slice(0, 3).map((course) => {
                const playlistId = getPlaylistId(course.playlistURL); // Get the playlist ID
                return (
                  <div
                    key={course._id}
                    className="home-course-card course-card-animation"
                  >
                    <h3>{course.title}</h3>
                    {playlistId ? (
                      <div>
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/videoseries?list=${playlistId}`}
                          title={course.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                          style={{
                            width: "100%",
                            height: "315px",
                            borderRadius: "8px",
                            marginTop: "10px",
                          }}
                        ></iframe>
                        <a
                          href={course.playlistURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="explore-now-button"
                        >
                          Explore Now
                        </a>
                      </div>
                    ) : (
                      <p>Playlist not available for this course.</p>
                    )}
                  </div>
                );
              })
            ) : (
              <p>No courses available.</p>
            )}
          </div>
        )}
      </section>
      {/* Documents Section */}
      <section className="documents">
        <h2>Essential Documents</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="document-grid">
            {documents.length > 0 ? (
              documents.slice(0, 5).map((document) => (
                <div key={document.title._id} className="document-card">
                  <a
                    href={`/documents/${document._id}`}
                    className="document-link"
                  >
                    <div className="document-icon">
                      {/* Replace with appropriate icon based on file type */}
                      <img
                        src={icons[document.fileType] || icons.default}
                        alt="Document Icon"
                      />
                    </div>
                    <div className="document-info">
                      <h3>{document.title}</h3>
                      {/* <p>{doc.description}</p> */}
                      <Link
                        to={`/documents/${document._id}`}
                        className="open-document-button"
                      >
                        Open Document
                      </Link>
                      {/* <button onClick={() => handleDelete(doc._id)}>Delete</button> */}
                    </div>
                  </a>
                </div>
              ))
            ) : (
              <p>No documents available.</p>
            )}
          </div>
        )}
      </section>
      {/* Projects Section */}
    </div>
  );
};

export default Home;
