import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/Documents/AddDocuments.css'; // Adjust path if necessary
import config from '../../config'; // Import the config file

const AddDocument = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fileType, setFileType] = useState('pdf'); // Default file type
  const [file, setFile] = useState(null);
  const [error, setError] = useState(''); // Error state for validation
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate file type
    if (file) {
      const fileExtension = file.name.split('.').pop();
      const allowedExtensions = {
        pdf: ['pdf'],
        docx: ['docx'],
        pptx: ['pptx'],
        xlsx: ['xlsx'],
        txt: ['txt'],
        zip: ['zip'],
      };

      if (!allowedExtensions[fileType].includes(fileExtension)) {
        setError(`Invalid file type. Please select a ${fileType.toUpperCase()} file.`);
        return;
      }
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('fileType', fileType);
    formData.append('file', file);

    try {
      const response = await fetch(`${config.apiUrl}/documents`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      console.log('Document added:', data);
      navigate('/documents'); // Redirect to the Documents page
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  return (
    <div className="add-document-container">
      <h1>Add New Document</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <label>
          File Type:
          <select
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
            required
          >
            <option value="pdf">PDF</option>
            <option value="docx">DOCX</option>
            <option value="pptx">PPTX</option>
            <option value="xlsx">XLSX</option>
            <option value="txt">TXT</option>
            <option value="zip">ZIP</option>
          </select>
        </label>
        <label>
          Document File:
          <input
            type="file"
            onChange={handleFileChange}
            required
          />
        </label>
        {error && <div className="error-message">{error}</div>}
        <button type="submit">Add Document</button>
      </form>
    </div>
  );
};

export default AddDocument;
