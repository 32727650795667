// src/components/Logout.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import config from "../../config";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        const user = auth.currentUser;

        if (user) {
          // Log the logout activity in your backend
          try {
            await fetch(`${config.apiUrl}/logLogoutActivity`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: user.uid, // Firebase user ID
              }),
            });
          } catch (loggingError) {
            console.error("Error logging logout activity", loggingError);
          }
        }

        // Sign out the user from Firebase
        await signOut(auth);

        // Redirect to the login page
        navigate("/login");
      } catch (error) {
        console.error("Error logging out:", error);
      }
    };

    performLogout();
  }, [navigate]);

  return <div>Logging out...</div>;
};

export default Logout;

// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { signOut } from 'firebase/auth';
// import { auth } from '../../firebase/firebase';

// const Logout = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const performLogout = async () => {
//       try {
//         await signOut(auth);
//         navigate('/login'); // Redirect to login page
//       } catch (error) {
//         console.error('Error logging out:', error);
//       }
//     };

//     performLogout();
//   }, [navigate]);

//   return (
//     <div>Logging out...</div>
//   );
// };

// export default Logout;
