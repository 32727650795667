import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/Documents/Documents.css"; // Ensure this path is correct
import icons from "../../icons/icons"; // Adjust path to import icons
import config from "../../config"; // Import the config file
import { getAuth } from "firebase/auth";

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  // Define the getDocuments function
  const getDocuments = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/documents`);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const docs = await response.json();
      return docs;
    } catch (error) {
      console.error("Error fetching documents:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const docs = await getDocuments();
      setDocuments(docs);
    };
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (user) {
      setUserEmail(user.email);
    }
  }, [user]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${config.apiUrl}/documents/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete document.");
      }

      // Remove deleted document from the state
      setDocuments(documents.filter((doc) => doc._id !== id));
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  return (
    <>
      {userEmail && userEmail.endsWith("@techtrapture.com") && (
        <Link to="/adddocument" className="add-document-button">
          Add New Document
        </Link>
      )}
      <div className="documents-list">
        {documents.map((doc) => (
          <div key={doc._id} className="document-card">
            <a href={`/documents/${doc._id}`} className="document-link">
              <div className="document-icon">
                {/* Replace with appropriate icon based on file type */}
                <img
                  src={icons[doc.fileType] || icons.default}
                  alt="Document Icon"
                />
              </div>
              <div className="document-info">
                <h3>{doc.title}</h3>
                {/* <p>{doc.description}</p> */}
                <Link
                  to={`/documents/${doc._id}`}
                  className="open-document-button"
                >
                  Open Document
                </Link>
                {/* <button onClick={() => handleDelete(doc._id)}>Delete</button> */}
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Documents;
